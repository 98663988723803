<template>
  <div>
    <AdvanceTable ref="advanceTable" url="erp/invoice" :extraClass="profile.accountCode ? 'embbed' : ''" tableSize="sm" :columns="columns" :selectable="true" :baseFilters="{organizationProfileId: profile.id, type: type, isShowDeleted: isShowDeleted, extendedInfo: true}" :height="profile.accountCode ? window.height - 410 : window.height - 310" v-loading="isLoading" @row-selected="onRowSelected">
      <template #button>
        <el-switch
          v-model="isShowDeleted"
          active-text="Show Deleted"
          inactive-text=""
          class="mr-1" @change="loadInvoices()">
        </el-switch>

        <el-button type="primary" size="small" @click="$refs.invoiceCreateView.createInvoice(profile, type)" v-if="getErpCan(getPermissionPrefix() + '.Create') && profile.accountCode" style=" margin-top: 34px;">
          <i class="el-icon-plus"></i> Create
        </el-button>

        <el-button-group class="mx-1">
          <el-button type="info" size="small" icon="el-icon-document" @click.exact="exportExcel('NORMAL')">
            Excel
          </el-button>

          <el-button type="info" size="small" icon="el-icon-sunny" @click.exact="exportExcelSun()">
            Sun
          </el-button>

          <el-button type="info" size="small" icon="el-icon-money" @click.exact="exportExcelCredit()">
            Credit
          </el-button>
        </el-button-group>
      </template>

      <template #cell(selection)="row">
        <input type="checkbox" :checked="row.rowSelected" style="pointer-events: none" :disabled="row.item.status !== 'PENDING'"/>
      </template>
      <template #head(selection)="row">
        <input type="checkbox" style="margin-top: 40px" ref="selectAll" @click="onSelectAllSelected">
      </template>
      <template #head(actions)="row">
        <div class="text-right">
          <el-link @click.stop="batchConfirm()" style="margin-top: 40px;">
            <i class="el-icon-check"></i>
            Batch Confirm
          </el-link>
        </div>
      </template>

      <template #cell(accountCode)="row">
        {{ row.item.accountCode }}
        <div class="text-muted" v-if="row.item.organizationProfile && row.item.accountCode !== row.item.organizationProfile.accountCode">{{ row.item.organizationProfile.accountCode }}</div>
      </template>
      <template #cell(totalAmount)="row">
        {{ row.item.totalAmount }} {{ row.item.totalAmountCurrency }}
      </template>
      <template #cell(sunStatusClosed)="row">
        <el-tag size="mini" type="primary" v-if="row.item.sunCloseTime">{{ $t('Closed') }}</el-tag>
        <el-tag size="mini" type="info" v-else>{{ $t('Pending') }}</el-tag>
      </template>
      <template #cell(statusCbo)="row">
        <div :style="getInvoiceIconStyle('CBO', row.item)">⬤</div>
      </template>
      <template #cell(statusFulfillment)="row">
        <div :style="getInvoiceIconStyle('Fulfillment', row.item)">⬤</div>
      </template>
      <template #cell(statusOthers)="row">
        <div :style="getInvoiceIconStyle('Others', row.item)">⬤</div>
      </template>
      <template #cell(status)="row">
        <InvoiceStatusTag :item="row.item"/>
      </template>
      <template #cell(invoiceTime)="row">
        {{ formatWithCurrentTimezone(row.item.invoiceTime) }}
      </template>
      <template #cell(actions)="row">
        <span v-if="row.item.status !== 'DELETED'">
          <el-link @click.stop="downloadInvoice(row.item)">
            <i class="el-icon-paperclip"></i>
            PDF
          </el-link>

          <el-link @click.stop="viewInvoice(row.item)">
            <i class="el-icon-view"></i>
            View
          </el-link>

          <el-link @click.stop="editInvoice(row.item)" v-if="getErpCan(getPermissionPrefix() + '.Edit')">
            <i class="el-icon-edit"></i>
            Edit
          </el-link>

          <el-link @click.stop="$refs.invoiceConfirmView.confirmInvoice(row.item)" v-if="getErpCan(getPermissionPrefix() + '.Confirm') && row.item.status === 'PENDING'">
            <i class="el-icon-check"></i>
            Confirm
          </el-link>

          <el-link @click.stop="$refs.invoiceConfirmFaView.confirmFaInvoice(row.item)" v-if="getErpCan(getPermissionPrefix() + '.FAConfirm') && row.item.status === 'CONFIRMED'">
            <i class="el-icon-check"></i>
            F&amp;A Confirm
          </el-link>

          <el-link @click.stop="$refs.invoiceCopyView.copyInvoice(row.item, profile)" v-if="getErpCan(getPermissionPrefix() + '.Copy')">
            <i class="el-icon-copy-document"></i>
            Copy
          </el-link>

          <el-link @click.stop="deleteInvoice(row.item)" v-if="getErpCan(getPermissionPrefix() + '.Delete') && row.item.confirmFaTime === null">
            <i class="el-icon-delete"></i>
            Delete
          </el-link>
        </span>

        <el-link @click="showAudits(row.item)" v-if="getErpCan(getPermissionPrefix() + '.Audits')">
          <i class="el-icon-search"></i>
          Audits
        </el-link>
      </template>
    </AdvanceTable>

    <el-dialog
      :title="$t('Export to Sun')"
      width="50%"
      :visible.sync="isShowSunExport"
    >
      <b-row>
        <b-col lg="4">
          <b-form-group :label="$t('Billing Period From')">
            <el-date-picker type="month" value-format="yyyy-MM-dd" v-model="exportSun.billingPeriodMonthFrom" size="mini"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="$t('Billing Period To')">
            <el-date-picker type="month" value-format="yyyy-MM-dd" v-model="exportSun.billingPeriodMonthTo" size="mini"/>
          </b-form-group>
        </b-col>
      </b-row>

      <div>
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" class="btn ml-1" @click="exportSunSubmit('SUN_EXCEL')">
            {{ $t('Excel') }}
          </b-button>

          <b-button variant="primary" class="btn ml-1" @click="exportSunSubmit('SUN_TEXT')">
            {{ $t('Text') }}
          </b-button>
        </div>
      </div>
    </el-dialog>



    <el-dialog
      :title="$t('Export Credit Report')"
      width="50%"
      :visible.sync="isShowCreditExport"
    >
      <b-row>
        <b-col lg="4">
          <b-form-group :label="$t('Invoice Created From')">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="exportCredit.invoiceTimeFrom" size="mini" style="width: 100%"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="$t('Invoice Created To')">
            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="exportCredit.invoiceTimeTo" size="mini" style="width: 100%"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="4">
          <b-form-group :label="$t('Billing Period From')">
            <el-date-picker type="month" value-format="yyyy-MM-dd" v-model="exportCredit.billingPeriodMonthFrom" size="mini" style="width: 100%"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group :label="$t('Billing Period To')">
            <el-date-picker type="month" value-format="yyyy-MM-dd" v-model="exportCredit.billingPeriodMonthTo" size="mini" style="width: 100%"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="4">
          <b-form-group :label="$t('Company Codes (One per line)')">
            <el-input type="textarea" v-model="exportCredit.accountCodes" :autosize="{ minRows: 3, maxRows: 5}"/>
          </b-form-group>
        </b-col>
      </b-row>

      <div>
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" class="btn ml-1" @click="exportCreditSubmit()">
            {{ $t('Export') }}
          </b-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('Delete Invoice ' + item.invoiceNumber)"
      width="50%"
      :visible.sync="isShowDeleteInvoice"
    >

      <!-- Select deleteReasons -->
      <div>
        <div class="w-100 d-flex">
          <b-form-group :label="$t('Delete Reason')">
            <el-select v-model="deleteReasonSelected" placeholder="Reason" size="mini" style="width: 300px">
              <el-option v-for="item in deleteReasons" :key="item" :label="item" :value="item"/>
            </el-select>
          </b-form-group>
        </div>
      </div>

      <div v-if="deleteReasonSelected === 'Other'">
        <div class="w-100 d-flex">
          <el-input v-model="deleteReason" size="mini" style="width: 300px"/>
        </div>
      </div>

      <div>
        <div class="w-100 d-flex justify-content-end">
          <b-button variant="primary" class="btn ml-1" @click="submitDeleteInvoice()">
            {{ $t('Delete') }}
          </b-button>
        </div>
      </div>
    </el-dialog>

    <AuditTrailModal ref="auditTrail"/>
    <InvoiceCreateModal ref="invoiceCreateView" :type="type" :profile="profile"></InvoiceCreateModal>
    <InvoiceCopyModal ref="invoiceCopyView" :type="type" :profile="profile"></InvoiceCopyModal>
    <InvoiceConfirmModal ref="invoiceConfirmView"></InvoiceConfirmModal>
    <InvoiceConfirmFaModal ref="invoiceConfirmFaView"></InvoiceConfirmFaModal>
    <InvoiceBatchConfirmModal ref="invoiceBatchConfirmView"></InvoiceBatchConfirmModal>
  </div>
</template>

<script>
import { BButton, BCard, BCardText, BCollapse, BModal, BTabs } from "bootstrap-vue";
import apis from "@/configs/apis";
import { EventBus } from "@/views/erp/utils/event-bus";
import { getInvoiceIconStyle } from "@/views/erp/utils/invoice";
import InvoiceStatusTag from "@/views/erp/components/InvoiceStatusTag.vue";
import { getAccessToken, getErpCan } from "@/utils/account-localstorage";
import AdvanceTable from "@/views/components/advanceTable/advanceTable.vue";
import axios from "axios";
import { formatWithCurrentTimezone } from "./utils/time";
import File from "@/views/erp/mixins/File";
import Prompt from "@/views/erp/mixins/Prompt";
import AuditTrailModal from "@/views/erp/components/AuditTrailModal.vue";
import InvoiceConfirmModal from "@/views/erp/components/InvoiceConfirmModal.vue";
import InvoiceConfirmFaModal from "@/views/erp/components/InvoiceConfirmFaModal.vue";
import Invoice from "@/views/erp/mixins/Invoice";
import InvoiceCopyModal from "@/views/erp/components/InvoiceCopyModal.vue";
import InvoiceCreateModal from "@/views/erp/components/InvoiceCreateModal.vue";
import AuditTrailList from "@/views/erp/AuditTrailList.vue";
import moment from "moment/moment";
import { MAX_PAGE_SIZE } from "@/views/erp/utils/system";
import InvoiceBatchConfirmModal from "@/views/erp/components/InvoiceBatchConfirmModal.vue";

export default {
  mixins: [File, Prompt, Invoice],
  components: {
    InvoiceBatchConfirmModal,
    AuditTrailList,
    InvoiceCreateModal,
    InvoiceCopyModal,
    InvoiceConfirmFaModal,
    InvoiceConfirmModal,
    AuditTrailModal,
    AdvanceTable,
    InvoiceStatusTag,
    BTabs,
    BCollapse,
    BButton,
    BModal,
    BCard,
    BCardText,
  },
  props: {
    profile: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.loadAll();
    EventBus.$on('invoice-updated', () => {
      this.loadInvoices();
    });
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    deleteReasonSelected: {
      handler: function(val) {
        if (val === 'Other') {
          this.deleteReason = '';
        } else {
          this.deleteReason = val;
        }
      }
    }
  },
  computed: {
    columns: function () {
      if (this.type === 'INVOICE') {
        return [
          { key: "selection", width: "30"},
          { key: "invoiceNumber", modelName: "invoiceNumber", label: "Invoice Number", width: "130", filtertype: "input", sortable: true },
          { key: "invoiceBy", modelName: "invoiceBy", label: "Invoice By", width: "150", filtertype: "input", sortable: true },
          !this.profile.id ? { key: "accountCode", modelName: "accountCode", label: "Company Code", width: "120", filtertype: "input", sortable: true } : {},
          { key: "customerReferenceNumber", modelName: "customerReferenceNumber", label: "Reference Number", width: "120", filtertype: "input", sortable: true },
          { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", width: "80", filtertype: "fiscal", sortable: true },
          { key: "accountPeriod", modelName: "accountPeriod", label: "Account Period", width: "80", filtertype: "fiscal", sortable: true },
          { key: "totalAmount", modelName: "totalAmount", label: "Total Amount", width: "120",  sortable: true },
          { key: "statusCbo", modelName: "statusCbo", label: "CBO", width: "40", align: "center" },
          { key: "statusFulfillment", modelName: "statusFulfillment", label: "Fulfillment", width: "40", align: "center" },
          { key: "statusOthers", modelName: "statusOthers", label: "Others", width: "40", align: "center" },
          { key: "status", modelName: "status", label: "Status", width: "120", align: "center", filtertype: "select", options: {  'SENT': 'Sent', 'FA_CONFIRMED': 'F&A Confirmed', 'CONFIRMED': 'Confirmed', 'PENDING': 'Pending', 'DELETED': 'Deleted'}, sortable: true  },
          { key: "sunStatusClosed", modelName: "sunStatusClosed", label: "Sun Status", width: "120", align: "center", filtertype: "select", options: {  'true': 'Closed', 'false': 'Pending' }, sortable: false  },
          { key: "invoiceTime", modelName: "invoiceTime", label: "Create Time", width: "200", filtertype: "datetime", sortable: true },
          { key: "actions", modelName: "actions", label: "Actions", align: "right" },
        ];
      } else {
        return [
          { key: "selection", width: "30"},
          { key: "invoiceNumber", modelName: "invoiceNumber", label: "Credit Note No.", width: "180", filtertype: "input", sortable: true },
          { key: "customerReferenceNumber", modelName: "customerReferenceNumber", label: "Reference Number", width: "180", filtertype: "input", sortable: true },
          { key: "billingPeriod", modelName: "billingPeriod", label: "Billing Period", width: "180", filtertype: "fiscal", sortable: true },
          { key: "totalAmount", modelName: "totalAmount", label: "Total Amount", width: "180",  sortable: true },
          { key: "status", modelName: "status", label: "Status", width: "150", align: "center", filtertype: "select", options: {  'SENT': 'Sent', 'FA_CONFIRMED': 'F&A Confirmed', 'CONFIRMED': 'Confirmed', 'PENDING': 'Pending', 'DELETED': 'Deleted'}, sortable: true  },
          { key: "sunStatusClosed", modelName: "sunStatusClosed", label: "Sun Status", width: "120", align: "center", filtertype: "select", options: {  'true': 'Closed', 'false': 'Pending' }, sortable: false  },
          { key: "actions", modelName: "actions", label: "Actions", align: "right"  },
        ];
      }
    }
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      exportSun: {
        billingPeriodMonthFrom: moment().format('YYYY-MM-DD'),
        billingPeriodMonthTo: moment().format('YYYY-MM-DD'),
      },
      exportCredit: {
        accountCodes: '',
        invoiceTimeFrom: moment().startOf('month').format('YYYY-MM-DD'),
        invoiceTimeTo: moment().endOf('month').format('YYYY-MM-DD'),
        billingPeriodMonthFrom: moment().format('YYYY-MM-DD'),
        billingPeriodMonthTo: moment().format('YYYY-MM-DD'),
      },
      item: {},
      deleteReasons: ['Finance Request', 'Wrong Invoice Setting', 'Other'],
      deleteReasonSelected: '',
      deleteReason: '',
      isLoading: false,
      isShowSunExport: false,
      isShowCreditExport: false,
      isShowDeleteInvoice: false,
      isShowDeleted: true,
      selected: []
    }
  },
  methods: {
    getErpCan,
    formatWithCurrentTimezone,
    getInvoiceIconStyle,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    onRowSelected(items) {
      for (let rowIndex = 0; rowIndex < this.$refs.advanceTable.rows.length; rowIndex += 1) {
        if (this.$refs.advanceTable.rows[rowIndex]['status'] !== 'PENDING') this.$refs.advanceTable.$refs.advanceTable.unselectRow(rowIndex);
      }

      this.selected = items;

      this.updateSelectAllState();
    },

    onSelectAllSelected() {
      if (this.$refs.selectAll.checked) {
        this.$refs.advanceTable.$refs.advanceTable.selectAllRows();
      } else {
        this.$refs.advanceTable.$refs.advanceTable.clearSelected();
      }
    },

    updateSelectAllState() {
      if (this.selected.length === 0) {
        this.$refs.selectAll.checked = false;
        this.$refs.selectAll.indeterminate = false;
      } else if (this.selected.length === this.$refs.advanceTable.rows.length) {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = false;
      } else {
        this.$refs.selectAll.checked = true;
        this.$refs.selectAll.indeterminate = true;
      }
    },

    async loadAll() {
      await this.loadInvoices();
    },

    loadInvoices() {
      if (this.$refs.advanceTable) this.$refs.advanceTable.loadList();
    },

    deleteInvoice(item) {
      this.isShowDeleteInvoice = true;
      this.item = item;
    },

    submitDeleteInvoice() {
      axios.delete(apis.erpInvoices + '/' + this.item.id, {
        data: { deleteReason: this.deleteReason },
      }).then(() => {
        this.isShowDeleteInvoice = false;
        this.promptInfo('Delete invoice successfully');
        EventBus.$emit("invoice-updated");
      }).catch((e) => {
        console.log(e)
        this.promptError(e);
      });
    },

    exportExcel(exportType, additionalFilters, overrideFilters = false) {
      this.isLoading = true;

      let urlParams = '';
      let params = this.$refs.advanceTable.filter;
      params.pageSize = MAX_PAGE_SIZE;

      if (!overrideFilters) {
        for (const k in params) {
          if (typeof params[k] != 'undefined') urlParams += k + "=" + params[k] + "&"
        }
      }

      if (additionalFilters) {
        for (const j in additionalFilters) {
          if (typeof additionalFilters[j] != 'undefined') urlParams += j + "=" + additionalFilters[j] + "&"
        }
      }

      axios.get(process.env.VUE_APP_ENDPOINT_URL + apis.erpInvoices.substring(1) + "/export?exportType=" + exportType + '&' + urlParams, {
        headers: { Authorization: `Bearer ${getAccessToken()}` },
        responseType: 'blob'
      }, this.$refs.advanceTable.filter)
        .then((response) => {
          const anchorElement = document.createElement('a');
          document.body.appendChild(anchorElement);
          anchorElement.style.display = 'none';

          const url = window.URL.createObjectURL(response.data);

          anchorElement.href = url;
          anchorElement.download = 'Invoice-Export' + (exportType === 'SUN_EXCEL' || exportType === 'SUN_TEXT' ? '-Sun' : '') + '.'  + (exportType === 'SUN_TEXT' ? 'txt' : 'xlsx');
          anchorElement.click();

          window.URL.revokeObjectURL(url);
        }).catch((e) => {
          this.promptError(e);
        }).finally(() => {
          this.isLoading = false;
        });
    },

    exportExcelSun() {
      this.isShowSunExport = true;
    },

    exportExcelCredit() {
      this.isShowCreditExport = true;
    },

    exportSunSubmit(type) {
      this.exportSun.billingPeriodMonthFrom = moment(this.exportSun.billingPeriodMonthFrom).startOf('month').format('YYYY-MM-DD');
      this.exportSun.billingPeriodMonthTo = moment(this.exportSun.billingPeriodMonthTo).endOf('month').format('YYYY-MM-DD');

      this.exportExcel(type, {
        billingPeriodStartFrom: this.exportSun.billingPeriodMonthFrom,
        billingPeriodEndTo: this.exportSun.billingPeriodMonthTo
      })
    },

    exportCreditSubmit() {
      this.exportExcel('CREDIT', {
        accountCodes: this.exportCredit.accountCodes.split('\n').join(","),
        invoiceTimeFrom: this.exportCredit.invoiceTimeFrom + 'T00:00:00.000%2b08:00',
        invoiceTimeTo: this.exportCredit.invoiceTimeTo + 'T23:59:59.000%2b08:00',
        billingPeriodMonthFrom: this.exportCredit.billingPeriodMonthFrom,
        billingPeriodMonthTo: this.exportCredit.billingPeriodMonthTo,
        sortBy: 'confirmTime',
        sortDirection: 'asc'
      }, true)
    },

    batchConfirm() {
      if (this.selected.length === 0) {
        this.promptError('Please select at least one invoice to confirm');
        return;
      }

      this.$refs.invoiceBatchConfirmView.confirmInvoices(this.selected);
    },

    showAudits(invoice) {
      this.$refs.auditTrail.showMultiple([
        {name: 'Invoice', className: 'com.kerrylogistics.dashboard.entities.erp.Invoice', objectId: invoice.id},
        {name: 'Invoice Item', className: 'com.kerrylogistics.dashboard.entities.erp.InvoiceItem', objectId: invoice.items.map(o => o.id)},
        {name: 'Invoice Documents', className: 'com.kerrylogistics.dashboard.entities.erp.InvoiceDocument', objectId: invoice.documents.map(o => o.id)}
      ])
    },

    getPermissionPrefix() {
      return this.type === 'INVOICE' ? 'Invoice' : 'CreditNote'
    }
  }
}
</script>
