<template>
  <el-dialog
    :title="'Batch Confirm ' + invoices.length + ' Invoices'"
    :visible.sync="isShow"
    width="80%"
  >
    <b-card no-body v-if="isAccrualEnabled()">
      <b-card-body>
        <b-row lg="12">
          <div>
            Accrual Billing Period:
            <el-select v-model="accrualBillingPeriod">
              <el-option v-for="item in sunCurrencyProfileBillingPeriods" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </b-row>
      </b-card-body>
    </b-card>

    <div v-else>
       Accrual not enabled. Are you sure to confirm this invoice?
    </div>

    <div class="w-100 d-flex justify-content-end">
      <el-button type="primary" class="ml-1" @click="confirmInvoicesSubmit()">Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isAccrualEnabled } from "@/views/erp/utils/system";
import axios from "axios";
import apis from "@/configs/apis";
import Invoice from "@/views/erp/mixins/Invoice";
import _ from "lodash";
import Prompt from "@/views/erp/mixins/Prompt";
import { EventBus } from "@/views/erp/utils/event-bus";

export default {
  mixins: [Prompt, Invoice],
  data() {
    return {
      isShow: false,
      invoices: [],
      accrualBillingPeriod: '',
      sunCurrencyProfileBillingPeriods: [],
    }
  },
  mounted() {
    this.loadSunCurrencyProfileItems();
  },
  methods: {
    isAccrualEnabled,
    confirmInvoices(invoices) {
      this.invoices = invoices;

      // if (!this.isChargeCodesValid(invoice)) {
      //  this.promptError('Invoice contains Charge Code that are not included in any Accruals');
      //  return;
      // }

      if (isAccrualEnabled()) {
        this.isShow = true;
      } else {
        this.$confirm('Confirm invoice?', 'Invoices:' + invoices.length)
          .then(() => {
            return this.confirmInvoicesSubmit(invoice);
          })
          .catch(e => {
            if (e === 'cancel') return;
            this.promptError(e);
          });
      }
    },

    async confirmInvoicesSubmit() {
      let promises = [];
      this.invoices.forEach(invoice => {
        promises.push(this.confirmInvoiceSubmit(invoice.id));
      });

      Promise.all(promises)
        .then(() => {
          this.isShow = false;
          this.promptInfo(this.invoices.length + ' Invoices Confirmed');
          EventBus.$emit("invoice-updated");
        })
        .catch(e => {
          this.promptError(e);
        });
    },

    async confirmInvoiceSubmit(invoiceId) {
      return axios.post(apis.erpInvoices + '/' + invoiceId + '/confirm', {accrualBillingPeriod: this.accrualBillingPeriod})
        .then(response => {
          this.isShow = false;
          EventBus.$emit("invoice-updated");
        })
        .catch(e => {
          this.promptError(e);
        }).finally(() => {
          this.isShowConfirmAccruals = false;
        });
    },

    loadSunCurrencyProfileItems() {
      axios.get(
        apis.erpGetMasterData + "/CURRENCY_PROFILE/SUN",
      ).then(response => {
        axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: response.value}})
          .then(response => {
            let billingPeriods = [];
            response.data.data.data.forEach(item => {
              billingPeriods.push(item.billingPeriod);
            });

            this.sunCurrencyProfileBillingPeriods = _.uniq(billingPeriods).sort().reverse();
          })
      });
    },
  }
}
</script>
